// File: src/hooks/useRatesProcessing.js

import { useCallback } from 'react';
import { processRateData, finalizeCalculations, additionalCalculations } from 'components/Dashboard/HotelRatesSearch/priceCalculations';

export function useRatesProcessing() {
  const processRates = useCallback((ratesData, additionalAdults, childrenCount) => {
    console.log('Processing rates data:', ratesData);
    const processedData = processRateData(ratesData, additionalAdults, childrenCount);
    console.log('Processed data:', processedData);

    if (processedData.noRates) {
      console.log('No rates available.');
      return {
        dailyPrices: [],
        rackRateTotal: 0,
        netRateTotal: 0,
        grandTotal: 0,
        noRates: true
      };
    } else {
      const finalizedData = finalizeCalculations(processedData);
      console.log('Finalized data:', finalizedData);
      const additionalData = additionalCalculations(finalizedData);
      console.log('Data after additional calculations:', additionalData);
      return additionalData;
    }
  }, []);

  return { processRates };
}