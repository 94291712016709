// File: src/components/Dashboard/HotelRatesSearch/priceCalculations.js

// Processes the rate data by adjusting rates based on the number of additional adults and children
export function processRateData(rateData, additionalAdults = 0, children = 0) {
    if (!rateData || rateData.length === 0) {
        return {
            dailyPrices: [],
            rackRateTotal: 0,
            netRateTotal: 0,
            grandTotal: 0,
            noRates: true // Add a flag to indicate no rates
        };
    }

    console.log('Initial Rate Data:', rateData);

    const result = rateData.reduce((acc, item) => {
        // Handle null or undefined rates
        const rackRateStr = item.rack_rate_with_taxes ? item.rack_rate_with_taxes : "0";
        const netRateStr = item.net_rate_with_taxes ? item.net_rate_with_taxes : "0";

        // Convert rates from strings to floats after removing non-numeric characters
        const rackRate = parseFloat(rackRateStr.replace(/[^0-9.-]+/g, ""));
        const netRate = parseFloat(netRateStr.replace(/[^0-9.-]+/g, ""));

        // Calculate additional charges for rack rate and net rate
        const rackRateAdultCharge = additionalAdults * (item.rack_rate_for_add_person || 0);
        const rackRateChildCharge = children * (item.rack_rate_for_add_child || 0);
        const netRateAdultCharge = additionalAdults * (item.net_rate_for_add_person || 0);
        const netRateChildCharge = children * (item.net_rate_for_add_child || 0);

        // Calculate total adjusted rates
        const adjustedRackRate = rackRate + rackRateAdultCharge + rackRateChildCharge;
        const adjustedNetRate = netRate + netRateAdultCharge + netRateChildCharge;

        // Accumulate totals
        acc.rackRateTotal += adjustedRackRate;
        acc.netRateTotal += adjustedNetRate;

        // Store daily prices with adjusted rates
        acc.dailyPrices.push({
            date: item.date,
            rackRate: adjustedRackRate,
            netRate: adjustedNetRate
        });

        return acc;
    }, {
        dailyPrices: [],
        rackRateTotal: 0,
        netRateTotal: 0,
        noRates: false // Ensure the noRates flag is set correctly
    });

    console.log('Processed Rate Data:', result);
    return result;
}

// Finalizes calculations by adding up the totals
export function finalizeCalculations(details) {
    details.grandTotal = details.netRateTotal; // Calculate the grand total from net rate total
    console.log('Finalized Calculations:', details);
    return details;
}

// Function to perform additional calculations like tax or discounts
export function additionalCalculations(details) {
    // Placeholder for any additional calculations
    console.log('Additional Calculations:', details);
    return details;
}