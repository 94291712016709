// src/components/Dashboard/Transfers/Dropdowns/PickupLocationDropdown.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { fetchPickupLocationsByYearId } from 'services/transfers_api';
import { useTransfers } from 'components/Dashboard/Transfers/TransfersContext';
import styles from 'styles/Transfers.module.css';

const PickupLocationDropdown = () => {
    const { yearId, handlePickupChange } = useTransfers();
    const [pickupLocations, setPickupLocations] = useState([]);
    const [selectedPickup, setSelectedPickup] = useState(null);

    useEffect(() => {
        const getPickupLocations = async () => {
            if (yearId) {
                try {
                    const locations = await fetchPickupLocationsByYearId(yearId);
                    setPickupLocations(locations.data);
                } catch (error) {
                    setPickupLocations([]);
                }
            }
        };

        getPickupLocations();
    }, [yearId]);

    const handlePickupSelect = (selectedOption) => {
        setSelectedPickup(selectedOption);
        handlePickupChange(selectedOption ? selectedOption.value : null, selectedOption ? selectedOption.label : '');
    };

    const options = pickupLocations.map(location => ({
        value: location.id,
        label: location.pickup_location,
    }));

    return (
        <Select
            classNamePrefix="react-select"
            value={selectedPickup}
            onChange={handlePickupSelect}
            options={options}
            placeholder="Select a pickup location"
            isClearable={true}
            isSearchable={true}
            className={styles.reactSelectControl}
        />
    );
};

export default PickupLocationDropdown;