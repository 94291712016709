// File: src/utils/rentalCalculations.js

const calculateQuote = (rates, days, season) => {
  let totalRack = 0;
  let totalNet = 0;

  // Adjusted to work with the actual season data structure
  if (!season || !season.daily_rack_rate || !season.daily_net_rate || !season.weekly_rack_rate || !season.weekly_net_rate) {
    console.error("Invalid season data:", season);
    return { totalRack, totalNet };
  }

  rates.forEach(rate => {
    console.log("Rate and Season Data:", rate, season);

    const weeks = Math.floor(days / 6);
    const remainingDays = days % 6;

    const weeklyChargeRack = weeks * parseFloat(season.weekly_rack_rate) || 0;
    const dailyChargeRack = remainingDays * parseFloat(season.daily_rack_rate) || 0;

    const weeklyChargeNet = weeks * parseFloat(season.weekly_net_rate) || 0;
    const dailyChargeNet = remainingDays * parseFloat(season.daily_net_rate) || 0;

    totalRack += weeklyChargeRack + dailyChargeRack;
    totalNet += weeklyChargeNet + dailyChargeNet;

    console.log("Intermediate Totals:", { totalRack, totalNet });
  });

  console.log("Final Calculated Quote:", { totalRack, totalNet });
  return { totalRack, totalNet };
};

export default calculateQuote;