// File: src/hooks/useOccupancyDetails.js

import { useState, useCallback } from 'react';

export const useOccupancyDetails = () => {
  const [additionalAdults, setAdditionalAdults] = useState(0);
  const [childrenCount, setChildrenCount] = useState(0);
  const [occupancyDetails, setOccupancyDetails] = useState(null);

  const resetState = useCallback(() => {
    setAdditionalAdults(0);
    setChildrenCount(0);
    setOccupancyDetails(null);
  }, []);

  return {
    additionalAdults,
    setAdditionalAdults,
    childrenCount,
    setChildrenCount,
    occupancyDetails,
    setOccupancyDetails,
    resetState,
  };
};