// File: src/components/Dashboard/Transfers/TransfersContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';

const TransfersContext = createContext();

export const TransfersProvider = ({ children }) => {
    const [yearId, setYearId] = useState(null);
    const [pickup, setPickup] = useState({ id: null, name: '' });
    const [dropOff, setDropOff] = useState({ id: null, name: '' });

    const handleYearChange = (id) => {
        setYearId(id);
    };

    const handlePickupChange = (id, name) => {
        setPickup({ id, name });
    };

    const handleDropOffChange = (id, name) => {
        setDropOff({ id, name });
    };

    useEffect(() => {
        // Optional: Perform side effects or log state changes if needed
    }, [yearId, pickup, dropOff]);

    return (
        <TransfersContext.Provider value={{
            yearId,
            pickupId: pickup.id,
            pickupName: pickup.name,
            dropOffId: dropOff.id,
            dropOffName: dropOff.name,
            handleYearChange,
            handlePickupChange,
            handleDropOffChange
        }}>
            {children}
        </TransfersContext.Provider>
    );
};

export const useTransfers = () => {
    return useContext(TransfersContext);
};