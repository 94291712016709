// src/hooks/useToursQuoteCalculator.js

import { useState, useEffect } from 'react';

export const useToursQuoteCalculator = (rate) => {
  const [regularAdults, setRegularAdults] = useState(0);
  const [regularChildren, setRegularChildren] = useState(0);
  const [privateAdults, setPrivateAdults] = useState(0);
  const [privateChildren, setPrivateChildren] = useState(0);
  const [additionalAmount, setAdditionalAmount] = useState(0);
  const [netQuote, setNetQuote] = useState(0);
  const [rackQuote, setRackQuote] = useState(0);

  useEffect(() => {
    if (rate) {
      // Use default value of 0 if any of these values are null or undefined
      const {
        reg_adults_net = 0,
        reg_children_net = 0,
        priv_adults_net = 0,
        priv_children_net = 0,
        reg_adults_rack = 0,
        reg_children_rack = 0,
        priv_adults_rack = 0,
        priv_children_rack = 0
      } = rate;

      const netQuoteCalc = (
        (regularAdults * parseFloat(reg_adults_net || 0)) +
        (regularChildren * parseFloat(reg_children_net || 0)) +
        (privateAdults * parseFloat(priv_adults_net || 0)) +
        (privateChildren * parseFloat(priv_children_net || 0)) +
        parseFloat(additionalAmount || 0)
      );

      const rackQuoteCalc = (
        (regularAdults * parseFloat(reg_adults_rack || 0)) +
        (regularChildren * parseFloat(reg_children_rack || 0)) +
        (privateAdults * parseFloat(priv_adults_rack || 0)) +
        (privateChildren * parseFloat(priv_children_rack || 0)) +
        parseFloat(additionalAmount || 0)
      );

      setNetQuote(netQuoteCalc);
      setRackQuote(rackQuoteCalc);

      console.log('Quotes updated:', {
        rate,
        regularAdults,
        regularChildren,
        privateAdults,
        privateChildren,
        additionalAmount,
        netQuoteCalc,
        rackQuoteCalc
      });
    }
  }, [regularAdults, regularChildren, privateAdults, privateChildren, additionalAmount, rate]);

  return {
    regularAdults, setRegularAdults,
    regularChildren, setRegularChildren,
    privateAdults, setPrivateAdults,
    privateChildren, setPrivateChildren,
    additionalAmount, setAdditionalAmount,
    netQuote, rackQuote,
  };
};