// src/components/Dashboard/HotelRatesSearch/0.Rates_and_Dates_Frontpage.js

import React from 'react';
import { HotelSearchProvider } from './HotelSearchContext';
import SearchBar from './SearchBar';
import PriceDisplay from './PriceDisplay';
import ShoppingCart from '../ShoppingCart/ShoppingCart'; // Import ShoppingCart component
import HotelInformation from './HotelInformation'; // Import HotelInformation component
import styles from 'styles/HotelSearchApp.module.css'; // Updated import

const RatesAndDatesFrontpage = () => {
  return (
    <HotelSearchProvider>
      <div className={styles.hotelSearchInterface}>
        <SearchBar />
        <PriceDisplay />
        <ShoppingCart />
        <HotelInformation />
      </div>
    </HotelSearchProvider>
  );
};

export default RatesAndDatesFrontpage;