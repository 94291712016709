// File: src/components/Dashboard/HotelRatesSearch/DatePickerComponent.js

import React, { useContext } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { HotelSearchContext } from './HotelSearchContext';

const DatePickerComponent = () => {
  const { selectedDates, onDateSelect } = useContext(HotelSearchContext);

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    if (end) {
      const adjustedEnd = new Date(end);
      adjustedEnd.setDate(adjustedEnd.getDate() - 1); // Subtract 1 day from the end date
      onDateSelect({ start, end: adjustedEnd });
    } else {
      onDateSelect({ start, end });
    }
  };

  return (
    <DatePicker
      selected={selectedDates.start}
      onChange={handleDateChange}
      startDate={selectedDates.start}
      endDate={selectedDates.end}
      selectsRange
      inline
    />
  );
};

export default DatePickerComponent;