// src/components/Dashboard/Transfers/Dropdowns/SelectYearDropdown.js

import React, { useState, useEffect } from 'react';
import { Panel, List } from 'rsuite';
import { fetchAvailableYears } from 'services/transfers_api';
import { useTransfers } from 'components/Dashboard/Transfers/TransfersContext';
import styles from 'styles/Transfers.module.css'; // Ensure the CSS module is imported

const SelectYearDropdown = () => {
    const { yearId, handleYearChange } = useTransfers();
    const [availableYears, setAvailableYears] = useState([]);
    const [selectedYearId, setSelectedYearId] = useState('');

    useEffect(() => {
        const getAvailableYears = async () => {
            try {
                const response = await fetchAvailableYears();
                if (response && response.data && Array.isArray(response.data)) {
                    const years = response.data;
                    setAvailableYears(years);
                    if (years.length > 0 && !yearId) {
                        handleYearChange(years[0].id);
                    }
                } else {
                    setAvailableYears([]);
                }
            } catch (error) {
                setAvailableYears([]);
            }
        };
        getAvailableYears();
    }, []); // Empty dependency array to ensure this runs only once

    useEffect(() => {
        if (yearId) {
            setSelectedYearId(yearId);
        }
    }, [yearId]);

    const handleYearSelect = (yearId) => {
        if (selectedYearId !== yearId) {
            setSelectedYearId(yearId);
            handleYearChange(yearId);
        }
    };

    return (
        <Panel header="Select Year" shaded className={styles.yearDropdownPanel}>
            <List>
                {availableYears.length > 0 ? (
                    availableYears.map((year) => (
                        <List.Item
                            key={year.id}
                            onClick={() => handleYearSelect(year.id)}
                            className={selectedYearId === year.id ? `${styles.activeYear} ${styles.rsuiteListItem}` : styles.rsuiteListItem}
                        >
                            {year.year}
                        </List.Item>
                    ))
                ) : (
                    <List.Item>Loading</List.Item>
                )}
            </List>
        </Panel>
    );
};

export default SelectYearDropdown;