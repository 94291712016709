// File: src/components/Dashboard/RentalCars/RentalCarsFrontpage.js

import React from 'react';
import RentalCarsDateComponent from 'components/Dashboard/RentalCars/RentalCarsDateComponent';
import RentalCarsRateDisplay from 'components/Dashboard/RentalCars/RentalCarsRateDisplay';
import RentalCarsQuoteGenerator from 'components/Dashboard/RentalCars/RentalCarsQuoteGenerator';
import { useRentalCars, RentalCarsProvider } from 'components/Dashboard/RentalCars/RentalCarsContext';
import styles from 'styles/RentalCars.module.css';

const RentalCarsFrontpageContent = () => {
  const { startDate, endDate } = useRentalCars();

  return (
    <div className={styles.rentalCarsFrontpage}>
      <div className={styles.datePickerContainer}>
        <RentalCarsDateComponent />
      </div>
      {/* 
      <div className={styles.rateDisplayContainer}>
        <RentalCarsRateDisplay />
      </div> 
      */}
      <div className={styles.rateDisplayContainer}>
        <RentalCarsQuoteGenerator />
      </div>
    </div>
  );
};

const RentalCarsFrontpage = () => (
  <RentalCarsProvider>
    <RentalCarsFrontpageContent />
  </RentalCarsProvider>
);

export default RentalCarsFrontpage;