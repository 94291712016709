// src/components/Dashboard/HotelRatesSearch/SummaryRow.js

import React from 'react';
import styles from 'styles/HotelSearchApp.module.css';

const SummaryRow = ({ startDate, endDate, netRateTotal, rackRateTotal, onToggle }) => {
  const formatDate = (date) => {
    return new Intl.DateTimeFormat('en-GB', {
      day: '2-digit',
      month: 'short',
      year: 'numeric',
    }).format(new Date(date));
  };

  return (
    <div>
      <table className={styles.priceTable}>
        <thead>
          <tr>
            <th>Check in - Check out</th>
            <th>Total Net Rate</th>
            <th>Total Rack Rate</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>{formatDate(startDate)} - {formatDate(endDate)}</td>
            <td>${netRateTotal.toFixed(2)}</td>
            <td>${rackRateTotal.toFixed(2)}</td>
          </tr>
        </tbody>
      </table>
      <button onClick={onToggle} className={styles.toggleButton}>
        Show Daily Breakdown
      </button>
    </div>
  );
};

export default SummaryRow;