// src/components/Dashboard/HotelRatesSearch/Dropdowns/HotelDropdown.js

import React, { useState, useEffect, useContext } from 'react';
import { fetchHotelsByRegion } from 'services/hotels_api';
import { HotelSearchContext } from '../HotelSearchContext';
import { Panel, List } from 'rsuite';
import styles from 'styles/HotelSearchApp.module.css';

function HotelDropdown() {
  const { selectedRegion, selectedHotel, setSelectedHotel, resetDependentStates } = useContext(HotelSearchContext);
  const [hotels, setHotels] = useState([]);

  useEffect(() => {
    setHotels([]);
  }, [selectedRegion]);

  useEffect(() => {
    const getHotels = async () => {
      if (!selectedRegion) return;
      try {
        const response = await fetchHotelsByRegion(selectedRegion);
        setHotels(response.data.results);
      } catch (error) {
        console.error('Error fetching hotels:', error);
      }
    };

    getHotels();
  }, [selectedRegion]);

  const handleHotelSelect = (hotelId) => {
    setSelectedHotel(hotelId);
  };

  return (
    <Panel header="Select Hotel" shaded className={styles.panel}>
      <List>
        {hotels.map((hotel) => (
          <List.Item
            key={hotel.id}
            onClick={() => handleHotelSelect(hotel.id)}
            className={`${selectedHotel === hotel.id ? styles.selected : ''} ${styles.rsuiteListItem}`}
          >
            {hotel.name}
          </List.Item>
        ))}
      </List>
    </Panel>
  );
}

export default HotelDropdown;