// src/components/Dashboard/HotelRatesSearch/HotelInformation.js

import React, { useEffect, useState, useContext } from 'react';
import { HotelSearchContext } from './HotelSearchContext';
import { fetchHotelDetails } from 'services/hotels_api';
import styles from 'styles/HotelSearchApp.module.css';

const HotelInformation = () => {
  const { selectedHotel } = useContext(HotelSearchContext);
  const [hotelDetails, setHotelDetails] = useState(null);

  useEffect(() => {
    const getHotelDetails = async () => {
      if (!selectedHotel) {
        setHotelDetails(null);
        return;
      }

      try {
        const response = await fetchHotelDetails(selectedHotel);
        const details = response.data;
        setHotelDetails(details);
      } catch (error) {
        console.error('Failed to fetch hotel details:', error);
      }
    };

    getHotelDetails();
  }, [selectedHotel]);

  return (
    <div className={styles.hotelDetailsContainer}>
      <div className={styles.hotelDetails}>
        <h3>Hotel Information</h3>
        {hotelDetails ? (
          <table className={styles.hotelDetailsTable}>
            <tbody>
              <tr>
                <td>Website:</td>
                <td>{hotelDetails.website}</td>
              </tr>
              <tr>
                <td>Phone Number:</td>
                <td>{hotelDetails.phone_number}</td>
              </tr>
              <tr>
                <td>WhatsApp Number:</td>
                <td>{hotelDetails.whatsapp_number}</td>
              </tr>
              <tr>
                <td>Email:</td>
                <td>{hotelDetails.email}</td>
              </tr>
              <tr>
                <td>Payment and Cancellation Policy:</td>
                <td>{hotelDetails.payment_and_cancellation_policy}</td>
              </tr>
              <tr>
                <td>Notes:</td>
                <td>{hotelDetails.notes}</td>
              </tr>
            </tbody>
          </table>
        ) : (
          <p>Select a hotel to see its information.</p>
        )}
      </div>
    </div>
  );
};

export default HotelInformation;