// File: src/services/hotels_api.js

import axios from 'axios';

const getAccessToken = () => localStorage.getItem('access_token');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
});

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.error("Access token is missing!");
    }
    console.log("Request Headers:", config.headers);
    return config;
  },
  (error) => Promise.reject(error)
);

// Existing functions
export const fetchCountries = () => api.get('/countries/');
export const fetchRegionsByCountry = (countryId) => 
  api.get('/regions/', { params: { country: countryId } });
export const fetchRegions = () => api.get('/regions/');
export const fetchHotelsByRegion = (regionId) =>
  api.get('/hotels/', { params: { region: regionId } });
export const fetchHotels = () => api.get('/hotels/');
export const fetchRoomTypesByHotel = (hotelId) =>
  api.get('/roomtypes/', { params: { hotel_id: hotelId } });
export const fetchRoomTypes = () => api.get('/roomtypes/');
export const fetchOccupancyTypesByRoomType = (roomTypeId) =>
  api.get('/occupancytypes/', { params: { room_type_id: roomTypeId } });
export const fetchOccupancyTypes = () => api.get('/occupancytypes/');
export const fetchRates = () => api.get('/rates/');
export const fetchGetRates = (params) => api.get('/get-rates/', { params });
export const fetchHotelDetails = (hotelId) => api.get(`/hotel/${hotelId}/`);

export default api;
