// src/components/Dashboard/HotelRatesSearch/Dropdowns/RoomOccupancyDropdown.js

import React, { useState, useEffect, useContext } from 'react';
import { Panel, Form, InputNumber, Tooltip, Whisper } from 'rsuite';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { HotelSearchContext } from '../HotelSearchContext';
import styles from 'styles/HotelSearchApp.module.css';
import { fetchRoomTypesByHotel } from 'services/hotels_api';

function RoomOccupancyDropdown() {
  const {
    selectedHotel,
    selectedRoomType, // Object containing { id, name }, we will use only `id` for the API call
    additionalAdults,
    setAdditionalAdults,
    childrenCount,
    setChildrenCount,
    occupancyDetails,
    setOccupancyDetails
  } = useContext(HotelSearchContext);

  const [baselineAdults, setBaselineAdults] = useState(0);
  const [maxTotalPeople, setMaxTotalPeople] = useState(0);

  useEffect(() => {
    const getOccupancyDetails = async () => {
      if (selectedHotel && selectedRoomType?.id) { // Use only selectedRoomType.id
        try {
          const response = await fetchRoomTypesByHotel(selectedHotel);
          const roomTypeData = response.data.results.find(roomType => roomType.id === selectedRoomType.id); // Use selectedRoomType.id for lookup

          if (roomTypeData) {
            setOccupancyDetails(roomTypeData.occupancy_details);
            setBaselineAdults(roomTypeData.occupancy_details.base_occupancy);
            setMaxTotalPeople(roomTypeData.occupancy_details.base_occupancy + roomTypeData.occupancy_details.max_add_people);
          } else {
            console.error('Selected room type not found:', selectedRoomType);
            setOccupancyDetails(null);
          }
        } catch (error) {
          console.error('Error fetching occupancy details:', error);
          setOccupancyDetails(null);
        }
      } else {
        setOccupancyDetails(null);
      }
    };

    getOccupancyDetails();
  }, [selectedHotel, selectedRoomType, setOccupancyDetails]);

  const handleAdditionalAdultsChange = (value) => {
    const remainingCapacity = maxTotalPeople - baselineAdults - childrenCount;
    if (value >= 0 && value <= remainingCapacity) {
      setAdditionalAdults(value);
    }
  };

  const handleChildrenChange = (value) => {
    const remainingCapacity = maxTotalPeople - baselineAdults - additionalAdults;
    if (value >= 0 && value <= remainingCapacity) {
      setChildrenCount(value);
    }
  };

  const occupancyTooltip = (
    <Tooltip>
      <div>Base occupancy is {baselineAdults}</div>
      <div>Max total number of people is {maxTotalPeople}</div>
      {occupancyDetails && <div>{occupancyDetails.define_add_person} pay as adults</div>}
    </Tooltip>
  );

  const childrenTooltip = (
    <Tooltip>
      {occupancyDetails && <div>{occupancyDetails.are_children_free}</div>}
      {occupancyDetails && <div>{occupancyDetails.define_child}</div>}
      {occupancyDetails && <div>Use the adults option for {occupancyDetails.define_add_person}</div>}
    </Tooltip>
  );

  return (
    <Panel header="Occupancy" shaded className={`${styles.panel} ${styles.roomOccupancyDropdown}`}>
      {occupancyDetails ? (
        <Form fluid>
          <Form.Group controlId="additionalPeople" className={styles.formGroup}>
            <Form.ControlLabel>
              Total Adults
              <Whisper trigger="hover" speaker={occupancyTooltip}>
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 8 }} />
              </Whisper>
            </Form.ControlLabel>
            <InputNumber
              min={baselineAdults}
              max={baselineAdults + (maxTotalPeople - baselineAdults - childrenCount)}
              value={baselineAdults + additionalAdults}
              onChange={(value) => handleAdditionalAdultsChange(value - baselineAdults)}
              className={styles.rsuiteInputNumber}
            />
          </Form.Group>
          <Form.Group controlId="children" className={styles.formGroup}>
            <Form.ControlLabel>
              Children 
              <Whisper trigger="hover" speaker={childrenTooltip}>
                <FontAwesomeIcon icon={faInfoCircle} style={{ marginLeft: 8 }} />
              </Whisper>
            </Form.ControlLabel>
            <InputNumber
              min={0}
              max={maxTotalPeople - baselineAdults - additionalAdults}
              value={childrenCount}
              onChange={handleChildrenChange}
              className={styles.rsuiteInputNumber}
            />
          </Form.Group>
        </Form>
      ) : <div></div>}
    </Panel>
  );
}

export default RoomOccupancyDropdown;