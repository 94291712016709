// src/components/Dashboard/Tours/ToursContext.js

import React, { createContext, useContext, useState } from 'react';

const ToursContext = createContext();

export const useTours = () => {
    return useContext(ToursContext);
};

export const ToursProvider = ({ children }) => {
    const [yearId, setYearId] = useState(null);
    const [regionId, setRegionId] = useState(null);
    const [tourNameId, setTourNameId] = useState(null);
    const [activityName, setActivityName] = useState(null);
    const [rate, setRate] = useState(null);
    const [providerId, setProviderId] = useState(null);
    const [providerName, setProviderName] = useState(null);  // Added providerName

    const handleYearChange = (id) => {
        setYearId(id);
        setRegionId(null);
        setTourNameId(null);
        setActivityName(null);
        setRate(null);
        setProviderId(null);
        setProviderName(null);  // Reset providerName when year changes
    };

    const handleRegionChange = (id) => {
        setRegionId(id);
        setTourNameId(null);
        setActivityName(null);
        setRate(null);
        setProviderId(null);
        setProviderName(null);  // Reset providerName when region changes
    };

    const handleActivityChange = (id, name) => {
        setTourNameId(id);
        setActivityName(name);
    };

    return (
        <ToursContext.Provider value={{
            yearId,
            regionId,
            tourNameId,
            activityName,
            rate,
            providerId,
            providerName,  // Expose providerName in context
            setRate,
            setProviderId,
            setProviderName,  // Expose setter for providerName
            handleYearChange,
            handleRegionChange,
            handleActivityChange
        }}>
            {children}
        </ToursContext.Provider>
    );
};