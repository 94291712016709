// src/components/Dashboard/Transfers/RateDisplay.js

import React, { useEffect, useState, useContext } from 'react';
import { fetchRates } from 'services/transfers_api';
import { useTransfers } from 'components/Dashboard/Transfers/TransfersContext';
import { ShoppingCartContext } from 'components/Dashboard/ShoppingCart/ShoppingCartContext';
import styles from 'styles/Transfers.module.css';

const RateDisplay = () => {
    const { yearId, pickupId, dropOffId, pickupName, dropOffName } = useTransfers();
    const { addItemToCart } = useContext(ShoppingCartContext);
    const [rates, setRates] = useState([]);
    const [buttonStates, setButtonStates] = useState({});

    useEffect(() => {
        const getRates = async () => {
            if (yearId && pickupId && dropOffId) {
                try {
                    const response = await fetchRates(yearId, pickupId, dropOffId);
                    if (response && response.data && Array.isArray(response.data)) {
                        setRates(response.data);
                        const initialButtonStates = response.data.reduce((acc, rate) => {
                            acc[rate.id] = {
                                text: 'Add to Itinerary',
                                class: styles.addToItineraryButton,
                                copyText: 'Copy',
                            };
                            return acc;
                        }, {});
                        setButtonStates(initialButtonStates);
                    } else {
                        setRates([]);
                    }
                } catch (error) {
                    setRates([]);
                }
            } else {
                setRates([]);
            }
        };

        getRates();
    }, [yearId, pickupId, dropOffId]);

    const handleAddToCart = (rate) => {
        const item = {
            id: rate.id,
            name: 'Transfer Rate',
            details: {
                groupSize: rate.group_size,
                netRate: rate.net_rate_with_taxes, // Use the displayed rate
                rackRate: rate.rack_rate_with_taxes, // Use the displayed rate
                pickupLocation: pickupName,
                dropOffLocation: dropOffName,
            },
            quantity: 1,
        };
        addItemToCart(item);
        setButtonStates(prevStates => ({
            ...prevStates,
            [rate.id]: {
                ...prevStates[rate.id],
                text: 'Added!',
                class: styles.addedToItineraryButton
            }
        }));
        setTimeout(() => {
            setButtonStates(prevStates => ({
                ...prevStates,
                [rate.id]: {
                    ...prevStates[rate.id],
                    text: 'Add to Itinerary',
                    class: styles.addToItineraryButton
                }
            }));
        }, 2000);
    };

    const handleCopyToClipboard = (rate) => {
        const textToCopy = `Jacamar\tTransfer from ${pickupName} to ${dropOffName} for ${rate.group_size}\t${rate.net_rate_with_taxes}\t${rate.rack_rate_with_taxes}`;
        navigator.clipboard.writeText(textToCopy).then(() => {
            setButtonStates(prevStates => ({
                ...prevStates,
                [rate.id]: {
                    ...prevStates[rate.id],
                    copyText: 'Copied'
                }
            }));
            setTimeout(() => {
                setButtonStates(prevStates => ({
                    ...prevStates,
                    [rate.id]: {
                        ...prevStates[rate.id],
                        copyText: 'Copy'
                    }
                }));
            }, 2000);
        });
    };

    if (!rates.length) {
        return <div className={styles.rateDisplay}>Select a transfer for rates</div>;
    }

    return (
        <div className={styles.rateDisplay}>
            <div className={styles.rateTableContainer}>
                <table className={styles.rateTable}>
                    <thead>
                        <tr>
                            <th>Group Size</th>
                            <th>Net Rate with Taxes</th>
                            <th>Rack Rate with Taxes</th>
                            <th>Add to Itinerary</th>
                            <th>Copy</th>
                        </tr>
                    </thead>
                    <tbody>
                        {rates.map(rate => {
                            const buttonState = buttonStates[rate.id] || {
                                text: 'Add to Itinerary',
                                class: styles.addToItineraryButton,
                                copyText: 'Copy'
                            };
                            return (
                                <tr key={rate.id}>
                                    <td>{rate.group_size}</td>
                                    <td>${rate.net_rate_with_taxes}</td>
                                    <td>${rate.rack_rate_with_taxes}</td>
                                    <td>
                                        <button 
                                            onClick={() => handleAddToCart(rate)} 
                                            className={buttonState.class}>
                                            {buttonState.text}
                                        </button>
                                    </td>
                                    <td>
                                        <button 
                                            onClick={() => handleCopyToClipboard(rate)} 
                                            className={styles.copyButton}>
                                            {buttonState.copyText}
                                        </button>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default RateDisplay;