// File: src/hooks/useHotelSelection.js

import { useState, useCallback } from 'react';

export const useHotelSelection = () => {
  const [selectedCountry, setSelectedCountry] = useState('');
  const [selectedRegion, setSelectedRegion] = useState('');
  const [selectedHotel, setSelectedHotel] = useState('');
  const [selectedRoomType, setSelectedRoomType] = useState('');

  const resetState = useCallback(() => {
    setSelectedRegion('');
    setSelectedHotel('');
    setSelectedRoomType('');
  }, []);

  return {
    selectedCountry,
    setSelectedCountry,
    selectedRegion,
    setSelectedRegion,
    selectedHotel,
    setSelectedHotel,
    selectedRoomType,
    setSelectedRoomType,
    resetState,
  };
};