// src/components/Dashboard/HotelRatesSearch/SearchBar.js

import React from 'react';
import CountryDropdown from './Dropdowns/CountryDropdown';
import RegionDropdown from './Dropdowns/RegionDropdown';
import HotelDropdown from './Dropdowns/HotelDropdown';
import RoomTypeDropdown from './Dropdowns/RoomTypeDropdown';
import RoomOccupancyDropdown from './Dropdowns/RoomOccupancyDropdown';
import DatePickerComponent from './DatePickerComponent';
import styles from 'styles/HotelSearchApp.module.css';

function SearchBar() {
  return (
    <div className={styles.searchBar}>
      <CountryDropdown />
      <RegionDropdown />
      <HotelDropdown />
      <RoomTypeDropdown />
      <RoomOccupancyDropdown />
      <div className={styles.calendarContainer}>
        <DatePickerComponent />
      </div>
    </div>
  );
}

export default SearchBar;