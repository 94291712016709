// File: src/components/Dashboard/ShoppingCart/ShoppingCartItem.js

import React, { useState, useEffect, useContext } from 'react';
import { ShoppingCartContext } from './ShoppingCartContext';

const ShoppingCartItem = ({ item }) => {
    const { removeItemFromCart, updateItemPrice } = useContext(ShoppingCartContext);
    const [isTenPercentAdded, setIsTenPercentAdded] = useState(false);

    useEffect(() => {
        if (!item) {
            console.error("Item is undefined");
            return;
        }
        console.log('ShoppingCartItem received item:', item);
    }, [item]);

    const handleToggleTenPercent = () => {
        if (!item) return;
        const adjustmentFactor = isTenPercentAdded ? 1 / 1.10 : 1.10;
        const newNetRate = item.details.netRate ? item.details.netRate * adjustmentFactor : item.details.netRateTotal * adjustmentFactor;
        const newRackRate = item.details.rackRate ? item.details.rackRate * adjustmentFactor : item.details.rackRateTotal * adjustmentFactor;
        const newPrice = item.details.price * adjustmentFactor;
        const updatedItem = {
            ...item,
            details: {
                ...item.details,
                netRate: newNetRate,
                netRateTotal: newNetRate,
                rackRate: newRackRate,
                rackRateTotal: newRackRate,
                price: newPrice,
            },
        };
        setIsTenPercentAdded(!isTenPercentAdded);
        updateItemPrice(updatedItem);
    };

    if (!item) {
        return null;
    }

    const renderItemDetails = () => {
        if (item.details.hotelName) {
            return (
                <>
                    <div>Hotel: {item.details.hotelName}</div>
                    <div>Check-in: {item.details.startDate}</div>
                    <div>Check-out: {item.details.endDate}</div>
                </>
            );
        } else if (item.details.pickupLocation && item.details.dropOffLocation) {
            return (
                <>
                    <div>Pickup: {item.details.pickupLocation}</div>
                    <div>Drop-off: {item.details.dropOffLocation}</div>
                </>
            );
        } else if (item.details.tourName) {
            return (
                <>
                    <div>Tour: {item.details.tourName}</div>
                    {item.details.regularAdults > 0 && <div>Regular Adults: {item.details.regularAdults}</div>}
                    {item.details.regularChildren > 0 && <div>Regular Children: {item.details.regularChildren}</div>}
                    {item.details.privateAdults > 0 && <div>Private Adults: {item.details.privateAdults}</div>}
                    {item.details.privateChildren > 0 && <div>Private Children: {item.details.privateChildren}</div>}
                </>
            );
        }
    };

    const getNetRate = () => {
        return item.details.netRateTotal ? item.details.netRateTotal.toFixed(2) : item.details.netRate.toFixed(2);
    };

    const getRackRate = () => {
        return item.details.rackRateTotal ? item.details.rackRateTotal.toFixed(2) : item.details.rackRate.toFixed(2);
    };

    return (
        <tr className="shopping-cart-item">
            <td>{renderItemDetails()}</td>
            <td>${getNetRate()}</td>
            <td>${getRackRate()}</td>
            <td>
                <div className="button-container">
                    <button onClick={() => removeItemFromCart(item.id)}>Remove</button>
                    <button onClick={handleToggleTenPercent}>
                        {isTenPercentAdded ? 'Remove 10%' : 'Add 10%'}
                    </button>
                </div>
            </td>
        </tr>
    );
};

export default ShoppingCartItem;