// src/components/Dashboard/Tours/Dropdowns/RegionDropdown.js

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fetchRegionsByYearId } from 'services/tours_api';
import { useTours } from 'components/Dashboard/Tours/ToursContext';
import styles from 'styles/Tours.module.css';

const RegionDropdown = () => {
    const { yearId, handleRegionChange, regionId } = useTours();
    const [regions, setRegions] = useState([]);
    const [selectedRegion, setSelectedRegion] = useState(null);

    useEffect(() => {
        if (!yearId) {
            setRegions([]);
            setSelectedRegion(null); // Reset selected region
            return;
        }

        const getRegions = async () => {
            try {
                const response = await fetchRegionsByYearId(yearId);
                const options = Array.isArray(response.data.results) ? response.data.results.map(region => ({
                    value: region.id,
                    label: region.name,
                })) : [];
                setRegions(options);

                const matchedOption = options.find(option => option.value === regionId);
                setSelectedRegion(matchedOption || null);
            } catch (error) {
                setRegions([]);
                console.error('Failed to fetch regions:', error);
            }
        };

        getRegions();
    }, [yearId, regionId]);

    const handleChange = (selectedOption) => {
        setSelectedRegion(selectedOption); // Set the selected option
        handleRegionChange(selectedOption ? selectedOption.value : null);
    };

    return (
        <Select
            classNamePrefix="react-select"
            value={selectedRegion} // Use the selected option object
            onChange={handleChange}
            options={regions}
            placeholder="Select a region"
            isClearable={true}
            isSearchable={true}
            isDisabled={!yearId}  // Disable if no year is selected
            className={styles.reactSelectControl}
        />
    );
};

export default RegionDropdown;