// src/components/Dashboard/HotelRatesSearch/PriceDisplay.js

import React, { useContext, useEffect, useState } from 'react';
import { HotelSearchContext } from './HotelSearchContext';
import { ShoppingCartContext } from '../ShoppingCart/ShoppingCartContext';
import { fetchHotelDetails } from 'services/hotels_api';
import SummaryRow from './SummaryRow';
import DailyBreakdownTable from './DailyBreakdownTable';
import styles from 'styles/HotelSearchApp.module.css';

const PriceDisplay = React.memo(() => {
  const { 
    priceDetails, 
    selectedHotel, 
    selectedDates, 
    resetState, 
    additionalAdults, 
    childrenCount, 
    selectedRoomType, 
    occupancyDetails 
  } = useContext(HotelSearchContext);
  
  const { addItemToCart } = useContext(ShoppingCartContext); 
  const [hotelName, setHotelName] = useState('Unknown Hotel');
  const [showDailyBreakdown, setShowDailyBreakdown] = useState(false); // Default to false
  const [copyButtonText, setCopyButtonText] = useState('Copy'); // Copy button state

  const { dailyPrices, rackRateTotal, netRateTotal, noRates } = priceDetails;

  useEffect(() => {
    const getHotelDetails = async () => {
      if (!selectedHotel) return;
      try {
        const response = await fetchHotelDetails(selectedHotel);
        const details = response.data;
        if (details && details.name) {
          setHotelName(details.name);
        }
      } catch (error) {
        console.error('Failed to fetch hotel details:', error);
      }
    };
    getHotelDetails();
  }, [selectedHotel]);

  const toggleDailyBreakdown = () => {
    setShowDailyBreakdown(!showDailyBreakdown); // Toggle visibility of breakdown
  };

  // Add a day to the checkout date
  const addOneDay = (date) => {
    const newDate = new Date(date);
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  };

  // Remove handleAddToCart temporarily

  // Function to handle copying dates and rates to clipboard
  const handleCopyToClipboard = () => {
    // Format dates as MM DD - MM DD, adding a day to checkout
    // Format dates as "03 Oct 2024 - 04 Oct 2024", adding a day to checkout
    const startDate = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(selectedDates.start));
    const checkoutDate = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short', year: 'numeric' }).format(addOneDay(new Date(selectedDates.end)));

    // Calculate total adults as baseline adults + additional adults
    const totalAdults = (occupancyDetails?.base_occupancy || 0) + additionalAdults;

    // Use room type name from context (without the label "Room Type")
    const roomTypeName = selectedRoomType?.name || 'Unknown';

    // Create the text to be copied, ensuring it's in the correct format
    const textToCopy = `${startDate} - ${checkoutDate}\t${hotelName}\t${roomTypeName}, Adults: ${totalAdults}, Children: ${childrenCount}\t${netRateTotal.toFixed(2)}\t${rackRateTotal.toFixed(2)}`;

    // Write text to clipboard
    navigator.clipboard.writeText(textToCopy).then(() => {
      // Temporarily change the copy button text to "Copied!"
      setCopyButtonText('Copied!');
      setTimeout(() => {
        setCopyButtonText('Copy'); // Revert the text back after 2 seconds
      }, 2000);
    });
  };

  if (!dailyPrices.length) {
    return null;
  }

  return (
    <div className={styles.priceDisplayContainer}>
      <div className={styles.priceDisplay}>
        <h3>Price Breakdown</h3>
        {noRates ? (
          <div>No rates available for the selected dates.</div>
        ) : (
          <>
            {!showDailyBreakdown ? (
              <SummaryRow 
                startDate={selectedDates.start}
                endDate={addOneDay(selectedDates.end)} // Add one day to checkout for the summary
                netRateTotal={netRateTotal}
                rackRateTotal={rackRateTotal}
                onToggle={toggleDailyBreakdown} // Keep breakdown hidden by default
              />
            ) : (
              <DailyBreakdownTable 
                dailyPrices={dailyPrices}
                netRateTotal={netRateTotal}
                rackRateTotal={rackRateTotal}
                onToggle={toggleDailyBreakdown}
              />
            )}
            {/* Buttons */}
            <div className={styles.buttonContainer}>
              {/* <button onClick={handleAddToCart} className={styles.addToItineraryButton}>
                Add to Itinerary
              </button> */}
              <button onClick={handleCopyToClipboard} className={styles.copyButton}>
                {copyButtonText}
              </button>
              <button onClick={resetState} className={styles.clearSearchButton}>
                Clear Search
              </button>
            </div>
          </>
        )}
      </div>
    </div>
  );
});

export default PriceDisplay;