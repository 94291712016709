import React, { useContext } from 'react';
import { ShoppingCartContext } from 'components/Dashboard/ShoppingCart/ShoppingCartContext';
import ShoppingCartItem from './ShoppingCartItem';
import 'styles/ShoppingCart.css';

const ShoppingCart = () => {
    const { cartItems, totalCost, totalRackRate, removeItemFromCart, emptyCart, updateItemPrice } = useContext(ShoppingCartContext);

    if (cartItems.length === 0) {
        // Render an invisible div when the cart is empty
        return <div className="shopping-cart invisible-cart"></div>;
    }

    return (
        <div className="shopping-cart">
            <h2>Itinerary Builder</h2>
            <table className="shopping-cart-table">
                <thead>
                    <tr>
                        <th>Item</th>
                        <th>Net Rate</th>
                        <th>Rack Rate</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {cartItems.map((item) => (
                        <ShoppingCartItem
                            key={item.id}
                            item={item}
                            removeItemFromCart={removeItemFromCart}
                            updateItemPrice={updateItemPrice}
                        />
                    ))}
                </tbody>
                <tfoot>
                    <tr>
                        <td><strong>Total</strong></td>
                        <td><strong>${totalCost.toFixed(2)}</strong></td>
                        <td><strong>${totalRackRate.toFixed(2)}</strong></td>
                        <td></td>
                    </tr>
                </tfoot>
            </table>
            <button onClick={emptyCart} className="empty-cart-button">Empty Cart</button>
        </div>
    );
};

export default ShoppingCart;