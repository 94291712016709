// File: src/services/transfers_api.js

import axios from 'axios';

const getAccessToken = () => localStorage.getItem('access_token');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Ensure this does not include `/api/`
});

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.error("Access token is missing!");
    }
    console.log("Request Headers:", config.headers);
    return config;
  },
  (error) => Promise.reject(error)
);

// Correct API endpoints based on urls.py

export const fetchAvailableYears = () => api.get('/transfers/years/');
export const fetchPickupLocationsByYearId = (yearId) => 
  api.get(`/transfers/years/${yearId}/pickup-locations/`);
export const fetchDropOffLocationsByPickup = (pickupId) => 
  api.get(`/transfers/pickup-locations/${pickupId}/dropoff-locations/`);
export const fetchRates = (yearId, pickupLocationId, dropoffLocationId) => 
  api.get(`/transfers/rates/${yearId}/${pickupLocationId}/${dropoffLocationId}/`);

export default api;