import axios from 'axios';

const getAccessToken = () => localStorage.getItem('access_token');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Ensure this does not include '/api'
});

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.error("Access token is missing!");
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Fetch all rental cars with their associated seasons
export const fetchRentalCarRates = () => api.get('/rental-cars/');

// Fetch a specific rental car by name
export const fetchRentalCarByName = (carName) => 
  api.get(`/rental-cars/${encodeURIComponent(carName)}/`);

export default api;