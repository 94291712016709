// File: src/components/Dashboard/RentalCars/RentalCarsDateComponent.js

import React from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useRentalCars } from 'components/Dashboard/RentalCars/RentalCarsContext';

const RentalCarsDateComponent = () => {
  const { startDate, endDate, onDateSelect } = useRentalCars();

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    onDateSelect({ start, end });  // Pass both start and end dates to the context
  };

  return (
    <DatePicker
      selected={startDate}
      onChange={handleDateChange}
      startDate={startDate}
      endDate={endDate}
      selectsRange
      inline
    />
  );
};

export default RentalCarsDateComponent;