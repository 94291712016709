// File: src/components/Dashboard/Tours/TourPolicy.js

import React, { useEffect, useState } from 'react';
import styles from 'styles/TourPolicy.module.css';
import { fetchPolicyTextByProviderId } from 'services/tours_api';
import { useTours } from './ToursContext';


const TourPolicy = () => {
    const { providerId, providerName } = useTours(); // Get providerId and providerName from context
    const [policyText, setPolicyText] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchPolicyText = async () => {
            try {
                if (providerId) {
                    const response = await fetchPolicyTextByProviderId(providerId);
                    setPolicyText(response.data.policy_text || 'No policy information available.');
                } else {
                    setPolicyText('No policy information available.');
                }
            } catch (error) {
                setError('Failed to fetch policy information.');
            } finally {
                setLoading(false);
            }
        };

        fetchPolicyText();
    }, [providerId]);

    if (loading) {
        return <p>Loading policy information...</p>;
    }

    if (error) {
        return <p style={{ color: 'red' }}>{error}</p>;
    }

    return (
        <div className={styles.policyTextContainer}>
            <h3>{providerName ? `${providerName} Policies` : 'Provider Policies'}</h3>
            <p>{policyText}</p>
        </div>
    );
};

export default TourPolicy;