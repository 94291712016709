// File: src/hooks/useHotelDataFetcher.js

import { useCallback, useContext } from 'react';
import { AuthContext } from 'components/Common/AuthContext';
import axios from 'axios';
import { fetchGetRates } from 'services/hotels_api'; // Import the function from hotels_api

export const useHotelDataFetcher = () => {
  const { authToken } = useContext(AuthContext);

  const fetchHotelData = useCallback(async (hotelId) => {
    const response = await axios.get(`/api/hotels/${hotelId}`, {
      headers: { Authorization: `Bearer ${authToken}` },
    });
    return response.data;
  }, [authToken]);

  const fetchRates = useCallback(async (roomTypeId, dates) => {
    const response = await fetchGetRates({
      room_type_id: roomTypeId,
      start_date: dates.start,
      end_date: dates.end,
    }); // Use the function from hotels_api
    return response.data;
  }, [authToken]);

  return { fetchHotelData, fetchRates };
};