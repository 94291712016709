// src/components/Dashboard/Transfers/0.Transfers_Frontpage.js

import React from 'react';
import TransfersSearchBar from './TransfersSearchBar'; // Import the TransfersSearchBar component
import RateDisplay from './RateDisplay';
import { TransfersProvider } from './TransfersContext';
import ShoppingCart from '../ShoppingCart/ShoppingCart'; // Import ShoppingCart component
import styles from 'styles/Transfers.module.css'; // Use the Transfers CSS module

const TransfersFrontpage = () => {
  return (
    <TransfersProvider>
      <div className={styles.transfersFrontpage}>
        <div className={styles.transfersSearchBar}>
          <TransfersSearchBar /> {/* Use the TransfersSearchBar component */}
        </div>
        <RateDisplay />
        <ShoppingCart /> 
      </div>
    </TransfersProvider>
  );
};

export default TransfersFrontpage;