// src/components/Dashboard/Transfers/Dropdowns/DropoffLocationDropdown.js

import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { fetchDropOffLocationsByPickup } from 'services/transfers_api';
import { useTransfers } from 'components/Dashboard/Transfers/TransfersContext';
import styles from 'styles/Transfers.module.css';

const DropOffLocationDropdown = () => {
    const { pickupId, handleDropOffChange } = useTransfers();
    const [dropOffLocations, setDropOffLocations] = useState([]);
    const [selectedDropOff, setSelectedDropOff] = useState(null);

    useEffect(() => {
        const getDropOffLocations = async () => {
            if (pickupId) {
                try {
                    const locations = await fetchDropOffLocationsByPickup(pickupId);
                    setDropOffLocations(locations.data);
                    setSelectedDropOff(null);
                } catch (error) {
                    setDropOffLocations([]);
                }
            } else {
                setDropOffLocations([]);
                setSelectedDropOff(null);
            }
        };

        getDropOffLocations();
    }, [pickupId]);

    const handleDropOffSelect = (selectedOption) => {
        setSelectedDropOff(selectedOption);
        handleDropOffChange(selectedOption ? selectedOption.value : null, selectedOption ? selectedOption.label : '');
    };

    const options = dropOffLocations.map(location => ({
        value: location.id,
        label: location.dropoff_location,
    }));

    return (
        <Select
            classNamePrefix="react-select"
            value={selectedDropOff}
            onChange={handleDropOffSelect}
            options={options}
            placeholder="Select a drop-off location"
            isClearable={true}
            isSearchable={true}
            className={styles.reactSelectControl}
        />
    );
};

export default DropOffLocationDropdown;