// src/components/Dashboard/Tours/Dropdowns/SelectYearDropdown.js

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fetchAvailableYears } from 'services/tours_api';
import { useTours } from 'components/Dashboard/Tours/ToursContext';
import styles from 'styles/Tours.module.css';

const SelectYearDropdown = () => {
    const { handleYearChange, yearId } = useTours();
    const [availableYears, setAvailableYears] = useState([]);
    const [selectedYear, setSelectedYear] = useState(null);

    useEffect(() => {
        const getAvailableYears = async () => {
            try {
                const response = await fetchAvailableYears();
                const options = Array.isArray(response.data.results) ? response.data.results.map(year => ({
                    value: year.id,
                    label: year.year,
                })) : [];
                setAvailableYears(options);

                const matchedOption = options.find(option => option.value === yearId);
                setSelectedYear(matchedOption || null);
            } catch (error) {
                setAvailableYears([]);
                console.error('Failed to fetch years:', error);
            }
        };
        getAvailableYears();
    }, [yearId]);

    const handleYearSelect = (selectedOption) => {
        setSelectedYear(selectedOption);
        handleYearChange(selectedOption ? selectedOption.value : null);
    };

    return (
        <Select
            classNamePrefix="react-select"
            value={selectedYear} // Use the selected option object
            onChange={handleYearSelect}
            options={availableYears}
            placeholder="Select a year"
            isClearable={true}
            isSearchable={true}
            className={styles.reactSelectControl}
        />
    );
};

export default SelectYearDropdown;