// File: src/components/App/App.js

import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { ShoppingCartProvider } from 'components/Dashboard/ShoppingCart/ShoppingCartContext';
import MainDashboard from 'components/Dashboard/MainDashboard';
import Login from 'components/LoginPage/Login';
import { AuthProvider } from 'components/Common/AuthContext';
import HotelSearchInterface from 'components/Dashboard/HotelRatesSearch/0.Rates_and_Dates_Frontpage';
import TransfersFrontpage from 'components/Dashboard/Transfers/0.Transfers_Frontpage';
import ToursFrontpage from 'components/Dashboard/Tours/0.Tours_Frontpage';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'styles/App.css';

function App() {
  return (
    <Router>
      <AuthProvider>
        <ShoppingCartProvider>
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/dashboard/*" element={<MainDashboard />} />
            <Route path="/hotelsearch" element={<HotelSearchInterface />} />
            <Route path="/transfers" element={<TransfersFrontpage />} />
            <Route path="/tours" element={<ToursFrontpage />} />
          </Routes>
          <ToastContainer />
        </ShoppingCartProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;