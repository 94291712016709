// File: src/components/LoginPage/Login.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from 'components/Common/AuthContext'; 
import 'styles/LoginPage.css';

function Login() {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [message, setMessage] = useState('');
    const { login } = useContext(AuthContext); 
    const navigate = useNavigate();

    const handleSubmit = async (event) => {
        event.preventDefault();
        setMessage('');
        try {
            await login(username, password);
            setMessage('Login successful! Redirecting...');
            navigate('/dashboard');
        } catch (error) {
            setMessage('Login failed. Please check your credentials.');
        }
    };

    return (
        <div className="page-container">
            <div className="content-container">
                <div className="logo-container">
                    <img src="/henny_logo.png" alt="Logo" className="login-logo"/>
                </div>
                <div className="login-container">
                    <form onSubmit={handleSubmit}>
                        <div className="form-group">
                            <label>Username:</label>
                            <input type="text" value={username} onChange={e => setUsername(e.target.value)} />
                        </div>
                        <div className="form-group">
                            <label>Password:</label>
                            <input type="password" value={password} onChange={e => setPassword(e.target.value)} />
                        </div>
                        <button type="submit" className="login-button">Login</button>
                    </form>
                    {message && <div className="login-message">{message}</div>}
                </div>
            </div>
        </div>
    );
}

export default Login;