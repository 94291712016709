// src/components/Dashboard/HotelRatesSearch/Dropdowns/RegionDropdown.js

import React, { useState, useEffect, useContext } from 'react';
import { fetchRegionsByCountry } from 'services/hotels_api';
import { HotelSearchContext } from '../HotelSearchContext';
import { Panel, List } from 'rsuite';
import styles from 'styles/HotelSearchApp.module.css';

function RegionDropdown() {
  const { selectedCountry, selectedRegion, setSelectedRegion, resetDependentStates } = useContext(HotelSearchContext);
  const [regions, setRegions] = useState([]);

  useEffect(() => {
    setRegions([]);
  }, [selectedCountry]);

  useEffect(() => {
    const getRegions = async () => {
      if (!selectedCountry) return;
      try {
        const response = await fetchRegionsByCountry(selectedCountry);
        setRegions(response.data.results);
      } catch (error) {
        console.error('Error fetching regions:', error);
      }
    };

    getRegions();
  }, [selectedCountry]);

  const handleRegionSelect = (regionId) => {
    setSelectedRegion(regionId);
  };

  return (
    <Panel header="Select Region" shaded className={styles.panel}>
      <List>
        {regions.map((region) => (
          <List.Item
            key={region.id}
            onClick={() => handleRegionSelect(region.id)}
            className={`${selectedRegion === region.id ? styles.selected : ''} ${styles.rsuiteListItem}`}
          >
            {region.name}
          </List.Item>
        ))}
      </List>
    </Panel>
  );
}

export default RegionDropdown;