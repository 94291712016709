// src/components/Dashboard/Tours/Dropdowns/ActivityDropdown.js

import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { fetchToursByRegionId } from 'services/tours_api';
import { useTours } from 'components/Dashboard/Tours/ToursContext';
import styles from 'styles/Tours.module.css';

const ActivityDropdown = () => {
    const { regionId, handleActivityChange, tourNameId } = useTours();
    const [activities, setActivities] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(null);

    useEffect(() => {
        if (!regionId) {
            setActivities([]);
            setSelectedActivity(null); // Reset selected activity
            return;
        }

        const getActivities = async () => {
            try {
                const response = await fetchToursByRegionId(regionId);
                const options = Array.isArray(response.data.results) ? response.data.results.map(activity => ({
                    value: activity.id,
                    label: activity.name,
                })) : [];
                setActivities(options);

                const matchedOption = options.find(option => option.value === tourNameId);
                setSelectedActivity(matchedOption || null);
            } catch (error) {
                setActivities([]);
                console.error('Failed to fetch tours:', error);
            }
        };

        getActivities();
    }, [regionId, tourNameId]);

    const handleChange = (selectedOption) => {
        setSelectedActivity(selectedOption); // Set the selected option
        handleActivityChange(selectedOption ? selectedOption.value : null, selectedOption ? selectedOption.label : null);
    };

    return (
        <Select
            classNamePrefix="react-select"
            value={selectedActivity} // Use the selected option object
            onChange={handleChange}
            options={activities}
            placeholder="Select a tour"
            isClearable={true}
            isSearchable={true}
            isDisabled={!regionId}  // Disable if no region is selected
            className={styles.reactSelectControl}
        />
    );
};

export default ActivityDropdown;