// File: src/components/Dashboard/RentalCars/RentalCarsQuoteGenerator.js

import React, { useState, useEffect } from 'react';
import { useRentalCars } from 'components/Dashboard/RentalCars/RentalCarsContext';
import calculateQuote from 'utils/rentalCalculations';
import styles from 'styles/RentalCars.module.css';

const RentalCarsQuoteGenerator = () => {
  const { rates, startDate, endDate } = useRentalCars();
  const [quotes, setQuotes] = useState([]);
  const [summary, setSummary] = useState('');
  const [copyButtonText, setCopyButtonText] = useState('Copy'); // Copy button state

  useEffect(() => {
    console.log("Component initialized with:", { startDate, endDate, rates });

    if (!startDate || !endDate || !rates.length) {
      console.error("Missing required data:", { startDate, endDate, rates });
      setSummary('Error: Invalid rental period or rates data.');
      return;
    }

    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (isNaN(startDateObj.getTime()) || isNaN(endDateObj.getTime())) {
      console.error("Invalid date objects created from startDate or endDate.", { startDateObj, endDateObj });
      setSummary('Error: Invalid date range provided.');
      return;
    }

    const targetDate = new Date(startDateObj.getFullYear(), startDateObj.getMonth(), startDateObj.getDate());
    targetDate.setHours(0, 0, 0, 0);

    const season = determineSeason(targetDate, rates[0].seasons);

    if (season && season.season_name) {
      console.log("Season determined:", season.season_name);

      const days = Math.ceil((endDateObj - startDateObj) / (1000 * 60 * 60 * 24));
      const weeks = Math.floor(days / 6);
      const remainingDays = days % 6;

      const calculatedQuotes = rates.map(rate => {
        console.log("Rate object before calculation:", rate);
        const selectedSeason = rate.seasons.find(s => s.season_name === season.season_name);
        console.log("Selected season for rate:", selectedSeason);
        const quote = calculateQuote([rate], days, selectedSeason);
        console.log("Calculated quote for rate:", { quote });
        return {
          ...rate,
          totalRack: quote.totalRack,
          totalNet: quote.totalNet
        };
      });

      setQuotes(calculatedQuotes);
      setSummary(`Total rental period: ${weeks} week(s) and ${remainingDays} day(s) | Season: ${season.season_name}`);
    } else {
      console.error("Error: Unable to determine season.");
      setSummary('Error: Unable to determine season.');
    }
  }, [rates, startDate, endDate]);

  const determineSeason = (targetDate, seasons) => {
    console.log("Determining season for date:", targetDate);

    for (const season of seasons) {
      if (!season || !season.start_date || !season.end_date) {
        console.error("Season data is missing start or end date:", season);
        continue;
      }

      const seasonStartDate = new Date(season.start_date);
      const seasonEndDate = new Date(season.end_date);
      seasonStartDate.setHours(0, 0, 0, 0);
      seasonEndDate.setHours(23, 59, 59, 999);

      if (targetDate >= seasonStartDate && targetDate <= seasonEndDate) {
        console.log(`Date is within the season ${season.season_name}`);
        return season;
      }
    }

    console.error("No matching season found for date", targetDate);
    return null;
  };

  const handleCopyToClipboard = (quote) => {
    const startDateFormatted = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(startDate));
    const endDateFormatted = new Intl.DateTimeFormat('en-US', { day: '2-digit', month: 'short', year: 'numeric' }).format(new Date(endDate));
    
    // Format the text for copying
    const textToCopy = `${startDateFormatted} - ${endDateFormatted}\tSIXT\t${quote.category} ${quote.car}\t${quote.totalNet?.toFixed(2) || 'N/A'}\t${quote.totalRack?.toFixed(2) || 'N/A'}`;

    // Copy to clipboard
    navigator.clipboard.writeText(textToCopy).then(() => {
      // Provide feedback by temporarily changing the button text
      setCopyButtonText('Copied!');
      setTimeout(() => {
        setCopyButtonText('Copy'); // Revert the text back after 2 seconds
      }, 2000);
    });
  };

  return (
    <div className={styles.quoteGeneratorContainer}>
      <h3 className={styles.quoteGeneratorTitle}>Rental Car Quotes</h3>
      <div className={styles.rateTableContainer}>
        <table className={styles.rateTable}>
          <thead>
            <tr>
              <th>Category</th>
              <th>Car</th>
              <th>Transmission</th>
              <th>Passengers</th>
              <th>Fuel</th>
              <th>Total Net Rate</th>
              <th>Total Rack Rate</th>
              <th>Copy</th> {/* New column for Copy button */}
            </tr>
          </thead>
          <tbody>
            {quotes.map(quote => (
              <tr key={quote.id}>
                <td>{quote.category}</td>
                <td>{quote.car}</td>
                <td>{quote.transmission}</td>
                <td>{quote.passengers}</td>
                <td>{quote.fuel}</td>
                <td>${quote.totalNet ? quote.totalNet.toFixed(2) : "N/A"}</td>
                <td>${quote.totalRack ? quote.totalRack.toFixed(2) : "N/A"}</td>
                <td>
                  <button onClick={() => handleCopyToClipboard(quote)} className={styles.copyButton}>
                    {copyButtonText}
                  </button>
                </td> {/* New column with Copy button */}
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="8" className={styles.summaryRow}>
                {summary}
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default RentalCarsQuoteGenerator;