// src/components/Dashboard/HotelRatesSearch/Dropdowns/CountryDropdown.js

import React, { useState, useEffect, useContext } from 'react';
import { fetchCountries } from 'services/hotels_api';
import { HotelSearchContext } from '../HotelSearchContext';
import { Panel, List } from 'rsuite';
import styles from 'styles/HotelSearchApp.module.css';

function CountryDropdown() {
  const { selectedCountry, setSelectedCountry, resetState } = useContext(HotelSearchContext);
  const [countries, setCountries] = useState([]);

  useEffect(() => {
    const getCountries = async () => {
      try {
        const response = await fetchCountries();
        const countryList = response.data.results;
        setCountries(countryList);

        if (!selectedCountry) {
          const costaRica = countryList.find(country => country.name === 'Costa Rica');
          if (costaRica) {
            setSelectedCountry(costaRica.id);
            resetState();
          }
        }
      } catch (error) {
        console.error('Error fetching countries:', error);
      }
    };

    getCountries();
  }, []);

  const handleCountrySelect = (countryId) => {
    setSelectedCountry(countryId);
    resetState();
  };

  return (
    <Panel header="Select Country" shaded className={`${styles.fixedHeight} ${styles.panel}`}>
      <List>
        {countries.map((country) => (
          <List.Item
            key={country.id}
            onClick={() => handleCountrySelect(country.id)}
            className={`${selectedCountry === country.id ? styles.selected : ''} ${styles.rsuiteListItem}`}
          >
            {country.name}
          </List.Item>
        ))}
      </List>
    </Panel>
  );
}

export default CountryDropdown;