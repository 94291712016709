// File: src/hooks/useDateSelection.js

import { useState, useCallback } from 'react';

export const useDateSelection = () => {
  const [selectedDates, setSelectedDates] = useState({ start: null, end: null });

  const onDateSelect = useCallback((dates) => {
    setSelectedDates(dates);
  }, []);

  const resetState = useCallback(() => {
    setSelectedDates({ start: null, end: null });
  }, []);

  return {
    selectedDates,
    setSelectedDates,
    onDateSelect,
    resetState,
  };
};