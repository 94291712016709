// File: src/components/Dashboard/RentalCars/RentalCarsContext.js

import React, { createContext, useContext, useState, useEffect } from 'react';
import api from 'services/rentalcars_api';  // Make sure the import path is correct

const RentalCarsContext = createContext();

export const useRentalCars = () => {
  return useContext(RentalCarsContext);
};

export const RentalCarsProvider = ({ children }) => {
  const [rates, setRates] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const onDateSelect = ({ start, end }) => {
    setStartDate(start);
    setEndDate(end);
  };

  useEffect(() => {
    const fetchRates = async () => {
      try {
        const response = await api.get('/rental-cars/');
        if (response.status === 200) {
          setRates(response.data.results);  // Setting only the results array
        } else {
          console.error('Failed to fetch rates:', response);
        }
      } catch (error) {
        console.error('Error fetching rates:', error);
      }
    };

    fetchRates();
  }, []);

  return (
    <RentalCarsContext.Provider value={{ rates, startDate, endDate, onDateSelect }}>
      {children}
    </RentalCarsContext.Provider>
  );
};

export default RentalCarsProvider;