// src/components/Dashboard/HotelRatesSearch/DailyBreakdownTable.js

import React from 'react';
import styles from 'styles/HotelSearchApp.module.css';

const DailyBreakdownTable = ({ dailyPrices, netRateTotal, rackRateTotal, onToggle }) => {

  // This function will extract just the YYYY-MM-DD part from the date string
  const formatDateString = (dateString) => {
    return dateString.split('T')[0]; // Use only the YYYY-MM-DD part, ignore time
  };

  return (
    <div>
      <table className={styles.priceTable}>
        <thead>
          <tr>
            <th>Date</th>
            <th>Net Rate</th>
            <th>Rack Rate</th>
          </tr>
        </thead>
        <tbody>
          {dailyPrices.map((price, index) => (
            <tr key={index}>
              {/* Directly use the date string without converting it to a Date object */}
              <td>{formatDateString(price.date)}</td> 
              <td>${price.netRate.toFixed(2)}</td>
              <td>${price.rackRate.toFixed(2)}</td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr className={styles.emphasized}>
            <td>Total</td>
            <td>${netRateTotal.toFixed(2)}</td>
            <td>${rackRateTotal.toFixed(2)}</td>
          </tr>
        </tfoot>
      </table>
      <button onClick={onToggle} className={styles.toggleButton}>
        Hide Daily Breakdown
      </button>
    </div>
  );
};

export default DailyBreakdownTable;