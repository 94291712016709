// src/services/tours_api.js

import axios from 'axios';

const getAccessToken = () => localStorage.getItem('access_token');

const api = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL, // Ensure this does not include '/api'
});

api.interceptors.request.use(
  (config) => {
    const token = getAccessToken();
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    } else {
      console.error("Access token is missing!");
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export const fetchAvailableYears = () => api.get('/tours/years/');
export const fetchRegionsByYearId = (yearId) => 
  api.get(`/tours/years/${yearId}/regions/`);
export const fetchToursByRegionId = (regionId) => 
  api.get(`/tours/regions/${regionId}/tours/`);
export const fetchTourRatesByTourNameId = (tourNameId) => 
  api.get(`/tours/tours/${tourNameId}/rates/`);
export const fetchPolicyTextByProviderId = (providerId) => 
  api.get(`/tours/policies/${providerId}/`);  // New API call to fetch policy by provider ID

export default api;