// File: src/components/Dashboard/ShoppingCart/ShoppingCartContext.js

import React, { createContext, useState, useEffect } from 'react';

const ShoppingCartContext = createContext();

export const ShoppingCartProvider = ({ children }) => {
    const [cartItems, setCartItems] = useState([]);
    const [totalCost, setTotalCost] = useState(0);
    const [totalRackRate, setTotalRackRate] = useState(0);

    const calculateTotalCost = (items) => {
        const newTotalNetRate = items.reduce((total, item) => {
            const netRate = item.details.netRate || item.details.netRateTotal || item.details.price || 0;
            return total + netRate;
        }, 0);

        const newTotalRackRate = items.reduce((total, item) => {
            const rackRate = item.details.rackRate || item.details.rackRateTotal || 0;
            return total + rackRate;
        }, 0);

        setTotalCost(newTotalNetRate);
        setTotalRackRate(newTotalRackRate);
    };

    const addItemToCart = (item) => {
        if (!item.id || cartItems.some(cartItem => cartItem.id === item.id)) {
            return;
        }
        setCartItems((prevItems) => {
            const updatedCartItems = [...prevItems, item];
            return updatedCartItems;
        });
    };

    const removeItemFromCart = (itemId) => {
        setCartItems((prevItems) => {
            const updatedCartItems = prevItems.filter(item => item.id !== itemId);
            return updatedCartItems;
        });
    };

    const updateItemPrice = (updatedItem) => {
        setCartItems((prevItems) => {
            const updatedCartItems = prevItems.map((item) =>
                item.id === updatedItem.id ? updatedItem : item
            );
            return updatedCartItems;
        });
    };

    const emptyCart = () => {
        setCartItems([]);
        setTotalCost(0);
        setTotalRackRate(0);
    };

    useEffect(() => {
        if (cartItems.length > 0) {
            calculateTotalCost(cartItems);
        }
    }, [cartItems]);

    return (
        <ShoppingCartContext.Provider
            value={{ cartItems, totalCost, totalRackRate, addItemToCart, removeItemFromCart, emptyCart, updateItemPrice }}
        >
            {children}
        </ShoppingCartContext.Provider>
    );
};

export { ShoppingCartContext };