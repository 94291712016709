// File: src/components/Dashboard/MainDashboard.js

import React from 'react';
import { Routes, Route, Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import logo from 'assets/dtalogo.png';
import HotelSearchInterface from 'components/Dashboard/HotelRatesSearch/0.Rates_and_Dates_Frontpage';
import TransfersSearchInterface from 'components/Dashboard/Transfers/0.Transfers_Frontpage';
import ToursSearchInterface from 'components/Dashboard/Tours/0.Tours_Frontpage';
import RentalCarsFrontpage from 'components/Dashboard/RentalCars/RentalCarsFrontpage';
import ShoppingCart from 'components/Dashboard/ShoppingCart/ShoppingCart';
import './Dashboard.css';

const Home = () => <div><h6>Select a tab above</h6></div>;

const NavigationBar = () => (
  <Navbar className="custom-navbar">
    <Container>
      <Navbar.Brand as={Link} to="/" className="navbar-brand">
        <img src={logo} alt="Logo" className="navbar-logo" />
      </Navbar.Brand>
      <Nav className="nav-right">
        <Nav.Item as={Link} to="/dashboard/hotelsearch">Hotels</Nav.Item>
        <Nav.Item as={Link} to="/dashboard/transfers">Transfers</Nav.Item>
        <Nav.Item as={Link} to="/dashboard/tours">Tours</Nav.Item>
        <Nav.Item as={Link} to="/dashboard/rentalcars">Rental Cars</Nav.Item>
      </Nav>
    </Container>
  </Navbar>
);

const Dashboard = () => (
  <>
    <NavigationBar />
    <Container style={{ overflowX: 'auto' }}>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="hotelsearch" element={<HotelSearchInterface />} />
        <Route path="transfers" element={<TransfersSearchInterface />} />
        <Route path="tours" element={<ToursSearchInterface />} />
        <Route path="rentalcars" element={<RentalCarsFrontpage />} />
      </Routes>
    </Container>
  </>
);

export default Dashboard;