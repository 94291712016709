// src/components/Dashboard/Transfers/TransfersSearchBar.js

import React from 'react';
import SelectYearDropdown from './Dropdowns/SelectYearDropdown';
import PickupLocationDropdown from './Dropdowns/PickupLocationDropdown';
import DropoffLocationDropdown from './Dropdowns/DropoffLocationDropdown';
import styles from 'styles/Transfers.module.css'; // Use the Transfers CSS module

const TransfersSearchBar = () => {
    return (
        <div className={styles.transfersSearchBar}>
            <SelectYearDropdown />
            <PickupLocationDropdown />
            <DropoffLocationDropdown />
        </div>
    );
};

export default TransfersSearchBar;