// File: src/components/Dashboard/Tours/ToursQuoteGenerator.js

import React, { useContext, useState } from 'react';
import { useTours } from './ToursContext';
import { ShoppingCartContext } from '../ShoppingCart/ShoppingCartContext';
import { useToursQuoteCalculator } from 'hooks/useToursQuoteCalculator';
import styles from 'styles/Tours.module.css';
import { v4 as uuidv4 } from 'uuid';

const ToursQuoteGenerator = () => {
    const { rate, activityName, providerName } = useTours();
    const {
        regularAdults, regularChildren, privateAdults, privateChildren, additionalAmount,
        setRegularAdults, setRegularChildren, setPrivateAdults, setPrivateChildren, setAdditionalAmount,
        netQuote, rackQuote
    } = useToursQuoteCalculator(rate);
    const { addItemToCart } = useContext(ShoppingCartContext);
    const [buttonText, setButtonText] = useState('Add to Itinerary');
    const [buttonClass, setButtonClass] = useState(styles.addToItineraryButton);
    const [copyButtonText, setCopyButtonText] = useState('Copy'); // New state for the copy button text

    if (!rate) {
        return null;
    }

    const handleAddToCart = () => {
        const item = {
            id: uuidv4(),
            details: {
                tourName: activityName,
                netRate: netQuote,
                rackRate: rackQuote,
                regularAdults,
                regularChildren,
                privateAdults,
                privateChildren,
                additionalAmount
            }
        };
        addItemToCart(item);
        setButtonText('Added!');
        setButtonClass(styles.addedToItineraryButton);
        setTimeout(() => {
            setButtonText('Add to Itinerary');
            setButtonClass(styles.addToItineraryButton);
        }, 2000);
    };

    const handleCopyToClipboard = () => {
        const participantList = [];

        if (regularAdults > 0) {
            participantList.push(`${regularAdults} regular adult(s)`);
        }
        if (regularChildren > 0) {
            participantList.push(`${regularChildren} regular child(ren)`);
        }
        if (privateAdults > 0) {
            participantList.push(`${privateAdults} private adult(s)`);
        }
        if (privateChildren > 0) {
            participantList.push(`${privateChildren} private child(ren)`);
        }

        const participantSummary = participantList.join(' and ');

        const textToCopy = `${providerName}\t${activityName} for ${participantSummary}\t${netQuote.toFixed(2)}\t${rackQuote.toFixed(2)}`;

        navigator.clipboard.writeText(textToCopy).then(() => {
            setCopyButtonText('Copied'); // Change the button text to "Copied"
            setTimeout(() => {
                setCopyButtonText('Copy'); // Revert the button text back to "Copy" after 2 seconds
            }, 2000);
        });
    };

    return (
        <div className={styles.quoteGenerator}>
            <h3 className={styles.quoteGeneratorTitle}>Quote Generator</h3>
            <div className={styles.quoteForm}>
                <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                        <label className={styles.formLabel}>Regular Adults</label>
                        <input
                            type="number"
                            value={regularAdults}
                            min="0" /* Prevents negative numbers */
                            onChange={(e) => setRegularAdults(Number(e.target.value))}
                            className={styles.formInput}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.formLabel}>Private Adults</label>
                        <input
                            type="number"
                            value={privateAdults}
                            min="0" /* Prevents negative numbers */
                            onChange={(e) => setPrivateAdults(Number(e.target.value))}
                            className={styles.formInput}
                        />
                    </div>
                </div>
                <div className={styles.formRow}>
                    <div className={styles.formGroup}>
                        <label className={styles.formLabel}>Regular Children</label>
                        <input
                            type="number"
                            value={regularChildren}
                            min="0" /* Prevents negative numbers */
                            onChange={(e) => setRegularChildren(Number(e.target.value))}
                            className={styles.formInput}
                        />
                    </div>
                    <div className={styles.formGroup}>
                        <label className={styles.formLabel}>Private Children</label>
                        <input
                            type="number"
                            value={privateChildren}
                            min="0" /* Prevents negative numbers */
                            onChange={(e) => setPrivateChildren(Number(e.target.value))}
                            className={styles.formInput}
                        />
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <label className={styles.formLabel}>Add expense manually</label>
                    <input
                        type="text"
                        value={additionalAmount}
                        onChange={(e) => setAdditionalAmount(Number(e.target.value.replace(/^0+/, '')))}
                        className={styles.formInput}
                        inputMode="numeric"
                        pattern="[0-9]*"
                    />
                </div>
            </div>
            <div className={styles.quoteResultsTableContainer}>
                <table className={styles.rateTable}>
                    <thead>
                        <tr>
                            <th>Provider</th> {/* Column 1: Provider Name */}
                            <th>Description</th> {/* Column 2: Activity Name and Participants */}
                            <th>Net Quote</th> {/* Column 3: Net Quote */}
                            <th>Rack Quote</th> {/* Column 4: Rack Quote */}
                            <th></th> {/* New column for action buttons */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{providerName}</td> {/* Column 1 */}
                            <td>
                                <div>{activityName}</div>
                                {regularAdults > 0 && <div>Regular Adults: {regularAdults}</div>}
                                {regularChildren > 0 && <div>Regular Children: {regularChildren}</div>}
                                {privateAdults > 0 && <div>Private Adults: {privateAdults}</div>}
                                {privateChildren > 0 && <div>Private Children: {privateChildren}</div>}
                            </td> {/* Column 2 */}
                            <td>${netQuote.toFixed(2)}</td> {/* Column 3 */}
                            <td>${rackQuote.toFixed(2)}</td> {/* Column 4 */}
                            <td>
                                <button onClick={handleCopyToClipboard} className={styles.copyButton}>
                                    {copyButtonText}
                                </button>
                            </td> {/* New column for copy button */}
                        </tr>
                    </tbody>
                </table>
            </div>
            <div className={styles.quoteButtons}>
                <button onClick={handleAddToCart} className={buttonClass}>
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default ToursQuoteGenerator;